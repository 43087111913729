export const adlist = [
    "0xcAB7e837A135dF6b1CF6d122d8cbED48698b5960",
    "0x60845F6a0D274164bDA86bDA4BEC6182FC46711A",
    "0xF6CC5A8E013e4607f9197d5F26F285cE5ECeDF37",
    "0x923b831B173b45c43C78E43898D73eE5A5530900",
    "0xD136EDFba7a7BE9977A280c97363b26c8962e6a5",
    "0x18C53906cBe54Ec7F64e3C56d5Cbd851e0Ad9a0C",
    "0x53E20B4c09Db3eE34D350f11D15b1D6C2E0B199e",
    "0x8173A1dF12a5673f71dE511d18Cc9F4a9f5C9783",
    "0xea686f3b619bfd8aed0ce60798afa6f08afe7f4a",
    "0x55c253fC4C3E25c93cedd8b972eb7bbEac3bCb7b",
    "0x8f478173e603464d090bd53664720fe7c556b3f1",
    "x88b94010f6925628c9623f45ff2b601dcea97ffa",
    "0x737289FDD3c07DD0EB23a2dAf8A6DFc5628df290",
    "0x53E20B4c09Db3eE34D350f11D15b1D6C2E0B199e",
    "0xB0AaDfA2f435fe3E42C96ce880BEC90aD8B73eBc",
    "0x38b9D9D3848A6c4192b6dcB94B112a8b39deE095",
    "0xb3faa137349dd5c76fc8ad80851c435946131e73",
    "0x2a277f246b1409CAE2Ea1BAB89D4341104ac395C",
    "0xB8a9610103629E9C56E12FadeB1C0db8b532F45D",
    "0xe0597cCFEdfE140B203596643ec65aDeD311AcAE",
    "0x775d0a816681567bf825f35fe43f3aa86b4722c5",
    "0xe1B2a0456b93F069343B85E767CA6DF23abF53E1",
    "0x240229eb565bBCC0bB876283C2A8E9Dcc9494915",
    "0x00cBF111191cE6979bf589719B3db7f9b1eC8974",
    "0x71A5c564E743666abFABa29f0C97e7c79d23cb6f",
    "0x6cc87C55CD3B0EB8cfE1E2163f6993F90138DAA1",
    "0x18C53906cBe54Ec7F64e3C56d5Cbd851e0Ad9a0C"
]