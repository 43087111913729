export const m = {
  lqlj: "Confirm claim ",
  sqinfo: "To use the features of your personal account, please use Wallet authorization ",
  sqbtn: "Wallet Authorization ",
  sqload: "Authorizing..." ,
  dltt: "Sign in to your account ",
  dlinfo: "To use the features of your personal account, click on the Link Wallet ",
  dlbtn: "Linked Wallet ",
  bdtt: "Bind parent ",
  bdinfo: "Please enter the referrer's wallet address ",
  bdbtn: "Confirm binding ",
  bdload: "binding..." ,
  bangsuc:"Binding successful",
  lqsg: "Claim successful ",
  lqsb: "Claim Failure ",
  day : "day ",
  shi:" Hour ",
  fen: "Fen!",
  miao:" Seconds ",
  fzcg:" Copy success ",
  fzsb:" Replication failure ",
  yhdj:" User Level ",
  zwkf:"Tip: Not yet open!",  
  mysj:" No data ",  
  wdyqlj:" My invitation link ",
  wdtjdz:"My recommended address",
  fz:" Copy ",
  home:"Home",
  qr:" Confirm ",
ny:" You have ",
wcqbqr:" Complete wallet confirmation ",
dqsl:" Authorized Quantity ",
cyje:" Participation amount ",
qsrcyje:" Please enter the participation amount ",
zfsl:" Payment quantity ",
qbye:" wallet balance ",
ljcy:" Get Involved Now ",
wdzc:" My Assets ",
klqzc:" Claimable Assets ",
jrsy:" Earnings Today ",
ljsy:" Cumulative Earnings ",
lq:" Claim ",
qrlq:" Confirm collection ",
min100:"Minimum 100",
wddd:' My Order ',
yxz:" In operation ",
ycj:" Out ",
cysj:" Participation Time ",
zfje:" Payment amount ",
cjjd:" Outgoing node ",

wdsq:" My Community ",
wddj:" My level ",
sfjd:" Node or not ",
shi:" Yes ",
fou:" No ",
kgdj:" Miner Level ",
wu:" No ",
cjkg:" Junior Miner ",
zjkg:" Intermediate Miner ",
gjkg:" Senior Miner ",
cjkgs:" Super Miner ",
cskg:" Genesis Miners ",
slzx:" Computing Power Center ",
grztslz:" Individual in the calculation of power value ",
grztrslz:" Personal Total Input Computing Power ",
ydztslz:" Generation is calculating power values ",
erztslz:" The second generation is calculating the power value ",
xqztslz:" The community is calculating the power value ",
sqztslz:" The community is calculating power values ",
sqxzyj:"New community performance",
wdfx:" My Share ",
wxyh:"Invalid user",
yxyh:"Active user",
cycg:"Participation success",
cysb:"Participation failure",
lyjh:"Chain polymerization",
kfz:"Under development...",
gglb:"List of announcements",
ggtt:"MICO is launched! ",
ggnr:"Warmly celebrate the world's first chain game aggregation platform, officially launch the era of token computing power 1.0, and open the treasure trove of chain game metaverse.",
dhcg:"Successful exchange",
dhsb:"Conversion failure",
bj:"Price",
zytz:"Important Notice",
wgxlk:"To thank new and old players for their support and trust in the platform, and to thank players for their tolerance and love for the platform. As the mainland Chinese New Year is approaching, the platform distributes benefits to those who agree, wishing the mainland community a happy and prosperous New Year in advance.",
jrq:"Starting today, the block will increase the daily block distribution hash power by 400,000 tokens. At the end of the New Year, the block will resume 200,000 tokens per day.",
syyz:"MICO Operations Team",
ggxx:"MICO officially wishes Chinese people around the world a happy New Year and good luck in the Year of the Snake. On the occasion of the Chinese New Year in 2025, in order to better expand the market and increase the income of the first mining period, the daily block block block minting will be increased to 1 million tokens per block starting from today, and adjustments will be made until the end of March. All DAO organizations should seize the opportunity and work hard to spread the message, sowing in spring and harvesting in autumn. Let's welcome the wealth feast brought by MICO together."
,ggxx1:"Dear MICO players: Due to malicious attacks on the server, data is disordered, and some accounts cannot be logged in or exchanged. The technology is currently working hard to repair, and we apologize for any inconvenience caused."

}